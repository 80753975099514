<template>
	<div class="list-info">
		<top-bar title="走访列表" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入走访人姓名" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
		<!-- 筛选区域 -->
		<div class="filterBar">
			<van-row>
				<van-col span="6">
					<p @click="gridShow = !gridShow" :style="{color:gridShow?'#387FF5':'#666666'}">
						{{gridName}}
						<img :src="require(`@/assets/img/${gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
					</p>
				</van-col>
				<van-col span="4">
					<p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">走访时间</p>
				</van-col>
				<van-col span="9">
					<p @click="userShow = !userShow" :style="{color:userShow?'#387FF5':'#666666'}">
						{{userName}}
						<img :src="require(`@/assets/img/${userShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
					</p>
				</van-col>
				<van-col span="4">
					<p @click="moreShow = !moreShow" :style="{color:moreShow?'#387FF5':'#666666'}">更多
						<img :src="require(`@/assets/img/${userShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
					</p>
				</van-col>
			</van-row>
		</div>
		<!-- 更多弹出层 -->
		<van-popup v-model="moreShow" position="right" :style="{ width: '90%', height: '100%'}" @open="moreClose">
      <div style="margin-bottom: 50px;">
        <van-cell title="走访类型"></van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="value in data.visitTypeList" :key="value.value">
            <van-tag size="large" @click="visitTypeClick(value)" :class="value.value === visitType.value?'checkedTag':'commonTag'">
              {{ value.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>

        <van-cell title="走访方式"></van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="value in data.visitWay" :key="value.value">
            <van-tag size="large" @click="visitWayClick(value)"
                     :class="value.value === visitWay.value?'checkedTag':'commonTag'">{{ value.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
      </div>
      <van-row style="position: absolute; bottom: 0; left: 0; width: 100%">
        <van-col span="12">
          <van-button block @click="moreCancel">重置</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" block @click="moreConfirm">确定</van-button>
        </van-col>
      </van-row>
      </van-popup>
      <!-- 所属社区弹出层 -->
    <van-popup v-model="gridShow" position="bottom">
      <van-picker title="请选择" show-toolbar :columns="data.gridList" value-key="label" @confirm="gridConfirm"
                  cancel-button-text="重置"
                  @cancel="gridCancel"  />
    </van-popup>
		<!-- 走访时间 -->
		<div class="dateBar" v-show="dateBarShow">
			<van-row>
				<van-col span="8" @click="beginDateShow = !beginDateShow">
					<span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span>
				</van-col>
				<van-col span="2"><span>-</span></van-col>
				<van-col span="8" @click="endDateShow = !endDateShow">
					<span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span>
				</van-col>
				<van-col span="3" @click="dateClose"><span>重置</span></van-col>
				<van-col span="3" @click="changeDate"><span>确定</span></van-col>
			</van-row>
		</div>
		<!-- 走访时间弹出层 -->
		<van-popup v-model="beginDateShow" position="bottom">
			<van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" :formatter="formatDate" type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()" />
		</van-popup>
		<van-popup v-model="endDateShow" position="bottom">
			<van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" :formatter="formatDate" type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)" />
		</van-popup>
		<!-- 走访用户弹出层 -->
    <van-popup v-model="userShow" position="bottom">
      <van-picker
          title="请选择" show-toolbar :columns="userList" value-key="label" @confirm="userConfirm"
          cancel-button-text="重置"
          @cancel="userCancel"
      />
    </van-popup>
		<!-- 添加按钮 -->
		<div class="addBtn" @click="goAdd">
			<img src="@/assets/img/add.png" alt="">
		</div>
		<!-- 内容区域 -->
		<div class="cont">
			<van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
				<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
					<div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
					<div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id, item.visitBody, item.fullLocation)">
						<div class="title">
							<div class="title-left">
								{{item.createUserName + ' | ' + item.createTime}}
							</div>
							<div class="title-right" :style="{color:item.status==10?'#FE5E5E':'#3E7EFE'}">
								{{item.serviceTypeStr}}
							</div>
						</div>
						<div class="content">
							<van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
								<template slot="error">
									<img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
								</template>
								<template slot="loading">
									<img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
								</template>
							</van-image>
							<div class="content-text">
								<div class="top-text">
                  <span style="font-weight: bold;">{{item.communityName}}</span>/<span style="font-size: 0.36rem;font-weight: 400;">{{item.visitBody == 2 ? item.placeStr : item.subareaName == null ? '无房户' : item.subareaName}}</span>
								</div>
								<div class="bottom-text" v-if="item.visitBody !== 2">走访对象：{{item.username}}</div>
                <div class="bottom-text" v-else>{{item.visitDes}}</div>
							</div>
							<van-icon name="arrow" color="#666" class="content-icon" />
						</div>
					</div>
				</van-list>
				<van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;">
				</van-image>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { formatterDate } from '@/utils/utils'
import { getBelongCommunity} from '@/utils/common'
export default {
  name: 'visit',
	components: {
		topBar,
	},
	data() {
		return {
			// 更多相关
			gridName: '本街道',
			searchValue: '',
			gridShow: false,
			dateBarShow: false, // 走访时间内容的显示与隐藏
			userShow: false,
			userName: '所属社工',
			moreShow: false,
			// 下拉刷新列表是否加载完成
			finished: true,
			// 列表是否处于加载状态
			loading: false,
			// 下拉刷新的状态
			pullLoading: false,
      dataList: [],
			// 走访数据
			data: {
        orgId: 0,
        createUserName: '',
        createTime: '',
        status: 20,
        serviceTypeStr: '',
        url: '',
        visitBody: 2,
        placeStr: '',
        fullLocation: '',
        visitDes: '',
        // 走访类型
        visitTypeList: [],
        // 走访方式
        visitWay: [{label: '上门走访', value: '1'}, {label: '电话约访', value: '2'}, {label: '集中座谈式', value: '3'},{label: '其他方式', value: '4'}],
				},
      page: 0,
      limit: 10,
			// 总数据条数
			totalCount: 10,
			// 走访时间
			beginDateShow: false,
			endDateShow: false,
			selectDate: {
				endDate: '',
				beginDate: '',
			},
      userList: [],
      visitType: {label: '', value: ''},
      visitWay: {label: '', value: ''},
      houseId: null,
      userId: null
		}
	},
	methods: {
		// 点击走访类型
    visitTypeClick(val) {
      this.visitType.label = val.label
      this.visitType.value = val.value
		},
		// 点击方式
		visitWayClick(val) {
      this.visitWay.label = val.label
      this.visitWay.value = val.value
		},
    // 更多弹框重置
    moreCancel() {
      this.visitType.label = ''
      this.visitType.value = ''
      this.visitWay.label = ''
      this.visitWay.value = ''
    },
    // 更多弹框确认
    moreConfirm() {
      this.dataList = []
      this.page = 0
      this.finished = false
      this.getDataList()
      this.moreShow = false
    },
    // 更多弹框关闭
    moreClose() {
      // this.moreShow = false
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.searchValue = ''
      this.getDataList()
    },
		// 选择网格的确定和取消事件
    gridConfirm(value,index){
      this.page = 0
      this.dataList = []
      this.gridName = value.label
      this.data.orgId = value.value
      this.getUserList()
      this.getDataList()
      this.gridShow = false
    },
    gridCancel(value){
      this.page = 0
      this.dataList = []
      this.gridName = '本街道'
      this.data.orgId = this.$globalData.userInfo.orgId
      this.getUserList()
      this.getDataList()
      this.gridShow = false
    },
		// 走访时间的确定重置按钮
		dateClose() {
			//重置
			this.selectDate = {
				endDate: '',
				beginDate: '',
			}
		},
		changeDate() {
      this.dataList = []
      this.page = 0
      this.finished = false
      this.getDataList()
		},
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
		// 走访用户选择完成事件
    userConfirm(value,index){
      this.page = 0
      this.dataList = []
      this.userName = value.label
      this.userId = value.value
      this.getDataList()
      this.userShow = false
    },
    userCancel(value){
      this.page = 0
      this.dataList = []
      this.userName = '所属社工'
      this.userId = ''
      this.getDataList()
      this.userShow = false
    },
		// 添加按钮
		goAdd() {
      this.$router.push({path: '/visit-add', query: this.$route.query, meta:{keepAlive: true}})
		},
		// 下拉刷新事件
		onPullDownRefresh() {
			console.log(123)
			this.pullLoading = true
			setTimeout(() => {
				this.pullLoading = false
			}, 2000)
		},
		// 滚动条与底部距离小于 offset 时触发
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/visit/list'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.data.orgId,
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          startDates: this.selectDate.beginDate,
          endDates: this.selectDate.endDate,
          workerType: this.userId,
          idNumber: '',
          username: '',
          labelCondition: 1,
          labels: [],
          houseId: this.houseId,
          visitType: this.visitType.value,
          serviceType: this.visitWay.value,
          clientType: 4
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          } else {
            this.finished = false
          }
          this.totalCount = data.page.totalCount
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getUserList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/orgUserManagerTree'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.data.orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          let list = []
          data.userManagerTree.map(item => {
            item.children.map(items => {
              list.push(items)
            })
          })
          this.userList = this.unique(list)
          // 默认显示当前社工名字
          let that = this
          this.userList.forEach(function(item) {
            if (item.value == that.$globalData.userInfo.userId) {
              that.userName = item.label
              return
            }
          });
          this.userList.unshift({
            label: '所有',
            value: 0
          })
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.value) && res.set(arr.value, 1));
    },
    // 获取字典
    getDictTypeList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'visitType',
          orgId: this.data.orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
            this.data.visitTypeList = data.dicts
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
		// 单个数据点击事件
		getInfo(id, visitType, location) {
			let type = ''
      if (visitType == 2) {
        type = '3'
      } else {
        type = location ? '1' : '2'
      }
      this.$router.push({path: '/visit-add', query: {id: id, type: type}})
		},
		// 格式化时间函数
		formatDate(type, val) {
			if (type === 'year') {
				return val + '年'
			}
			if (type === 'month') {
				return val + '月'
			}
			if (type === 'day') {
				return val + '日'
			}
			return val
		},
	},
  mounted () {
    if (this.$route.query.houseId) {
      this.houseId = this.$route.query.houseId
    }
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.userId = this.$globalData.userInfo.userId
    this.data.orgId = this.$globalData.userInfo.orgId
    getBelongCommunity(this.$orgId, -1, (communityList) => {
      this.data.gridList = communityList
      this.data.gridList.unshift({
        label: '本街道',
        value: Number(this.data.orgId)
      })
    })
    this.getDataList()
    this.getUserList()
    this.getDictTypeList()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-grid-item__content {
  padding: 15px 0 !important;
}
.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  font-size: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}

.checkedTag {
  width: 100%;
  color: #387ff5;
  background-image: url("../../assets/img/activebg.png");
  background-size: 100% 100%;
  border: 1px solid #387ff5;
}

.commonTag {
  background-color: #f0f2f5;
  color: #666666;
}
.van-popup--right .van-cell {
  font-size: 26px;
  padding: 20px 32px 7.5px;
}
</style>
