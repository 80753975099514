import { render, staticRenderFns } from "./visit.vue?vue&type=template&id=ebf2ccf4&scoped=true&"
import script from "./visit.vue?vue&type=script&lang=js&"
export * from "./visit.vue?vue&type=script&lang=js&"
import style0 from "./visit.vue?vue&type=style&index=0&id=ebf2ccf4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebf2ccf4",
  null
  
)

export default component.exports